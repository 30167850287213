import React, { useEffect, useState } from "react";
import { getEvents } from "../../apis/publicApis";
import SmallBanner from "../../components/small.banner/small.banner";
import EventsListingBreadcrumbs from "./sections/breadcrumbs/events.listing.breadcrumbs";
import EventsListingDetails from "./sections/details/events.listing.details";

function EventsListingPage() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getEvents()
      .then((res) => {
        console.log(res)
        let evdata = [];
        res?.data?.forEach((item) => {
          let evItem = {
            startTime: item?.event_start_date,
            listingImage: item?.thumb,
            title: item?.name,
            id: item?.id,
            link: "/events/",
            location: item?.mode,
            status: item?.status,
            cardDescription: item?.description,
          };
          evdata.push(evItem);
        });
        setData(evdata);
      })
      .catch((err) => {
        console.log(err, "error")
      });
  }, []);
  return (
    <div>
      <SmallBanner title="EVENTS" />
      {/* <EventsListingBreadcrumbs /> */}
      <div className="pt-4 greyBackground"></div>
    
      <EventsListingDetails data={data} />
      <div className="pb-4 greyBackground"></div>
    </div>
  );
}

export default EventsListingPage;
