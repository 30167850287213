import React, { useReducer } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logoutUser } from "./apis/auth.apis";
import { refreshToken } from "./config";
import ScrollToTop from "./hoc/scrollToTop";
import AboutPage from "./pages/about/about.page";
import AdvisoryCommittee from "./pages/advisory_committee/advisory.committee.page";
import ManageAccount from "./pages/auth/ManageAccount";
import EdcRegistration from "./pages/edc.registration/EdcRegistration";
import FaqPage from "./pages/faq/faq.page";
import HomePage from "./pages/home/home.page";
import Inovation from "./pages/inovation/inovation.page";
import ProgramsPage from "./pages/programs/programs.page";
import RegistrationPage from "./pages/registration/registration.page";
import SuccessPage from "./pages/success/success.page";
import TeamPage from "./pages/team/team.page";
import IndustryAccademyPage from "./pages/Industry-academy_collab/industry-academy.page";
import AuthRoute from "./routes/auth/auth.route";
import PrivateRoute from "./routes/private/private.route";
import PublicRoute from "./routes/public/public.route";
import { loginReducer } from "./utils/reducers";
import IndAccProgramPage from "./pages/industry-academy_colab_programs/industry-academy_programs.page";
import EDCPage from "./pages/Entrepreneurship_Development_Club/edc.page";
import SchemesPage from "./pages/SchemesPage/schemes.page";
import EventsPage from "./pages/eventsPage/events.page";
import SubmitSolutionsPage from "./pages/Submit-solutions/submitSolutions.page";
import ReportProblemPage from "./pages/reportProblem/reportProblem.page";
import DirectoryPage from "./pages/directory/directory.page";
import EDCProgramPage from "./pages/edc-program/edcProgram.page";
import NewsPage from "./pages/news/news.page";
import NewPasswordPage from "./pages/Set_password/new_password.page";
import TendersPage from "./pages/tenders/tenders.page";
import BlogsPage from "./pages/blogs/blogs.page";
import BlogDetailPage from "./pages/blog-detail/blog.detail.page";

import CampusPage from "./pages/campus/campus.page";
import InternshipDetailPage from "./pages/internship-detail/internship.detail.page";
import InternshipSummaryPage from "./pages/internship_summary_list/internship.summary.page";
import NewsDetailPage from "./pages/news/details/news.detail";
import SubmitIntershipPage from "./pages/submit_internship/submit.internship.page";
import KsidcSchemesPage from "./pages/ksidc-Scheme/schemes.page";
import EDCSchemesPage from "./pages/EDClub-scheme/schemes.page";
import InternshipApplicationPage from "./pages/internship_application/internship.application.page";
import ContactUs from "./pages/contact_us/ContactUs";
import OurWebinar from "./pages/our_webinars/OurWebinar";
import KiedVideos from "./pages/kied_videos/KiedVideos";
import InnovationEventDetails from "../src/pages/inovation/sections/Events/details/InnovationEventDetails";
import EdcEventDetails from "../src/pages/Entrepreneurship_Development_Club/sections/events/details/EdcEventDetails";

import UnderConstruction from "./pages/under_construction/under.construction";
import ResourceProgramPage from "./pages/resource_programs/resource.programs.page";
import ResourcesEventsPage from "./pages/resources_events/resource.events.page";
import WebinarPage from "./pages/webinar_rec/webinar.page";
import NewProgramsPage from "./pages/new_programs/new.programs.page";
import AriseRegistration from "./pages/new_programs/sections/ariseRegistration/arise.registration";
import EdcNewProgram from "./pages/edc.new.program/EdcNewProgram";
import EventsListingPage from "./pages/eventslisting/events.listing.page";
import BuildingProgramSchemes from "./pages/ACN-scheme-one/BuildingProgramSchemes";
import AssistancePromotion from "./pages/ACN-scheme-two/AssistancePromotion";
import MarketInfrastructure from "./pages/ACN-scheme-three/MarketInfrastructure";
import InternshipEventDetails from "./pages/Industry-academy_collab/sections/events/details/InternshipEventDetails";
import MaterialsPage from "./pages/materials/tenders.page";
import AspirantForm from "./pages/aspirantform/aspirantform";
import ExistingEntForm from "./pages/existinentform/extentform";
import EDCForm from "./pages/edcform/edcform";
import ResearchForm from "./pages/researchform/researchform";
import IndustryForm from "./pages/industryform/industryform";
import ExpertForm from "./pages/expertform/expertform";
import DataForm from ".//pages/dataform/dashboard";
import ProgramsListingPage from "./pages/programsListing/events.listing.page";
import SchemesListingPage from "./pages/schemesListing/events.listing.page";
import CarrersPage from "./pages/carrers/tenders.page";
import SchemeDetailPage from "./pages/schemesDetails/schemes.page";
import InnovationStatEventDetails from "./pages/inovation/sections/Events/staticdetail/InnovationEventDetails";
import ProgramDetailPage from "./pages/program-detail/new.programs.page";
import Terms from "./pages/TC/terms";
import VerifyEmail from "./pages/auth/verify-email";
import BgpGoogleForm from "./pages/new_programs/bgpGoogle";
import HomeNewsDetails from "./pages/home/sections/newsDetail/NewsDetails";
import PaymentListing from "./components/payment-cards/paymantlisting";
import PaymentDetails from "./pages/Payment-page/paymantDetails";
import PaymentSuccessPage from "./pages/paymentSuccess/success.page";
import { useEffect } from "react";
import EnterpreneurshipDevelopmentCenter from "./pages/enterpreneurshipDevelopmentCenter/inovation.page";
import ProgramEnterpriseDetailPage from "./pages/program-detail-enterprise/new.programs.page";
import NewAdminPasswordPage from "./pages/set_admin_password/new_password.page";

export const Logout = () => {
  logoutUser();
  refreshToken(null);

  localStorage.clear();
  localStorage.removeItem("mv_token");
  localStorage.removeItem("user");
  localStorage.removeItem("nextPage");
  localStorage.removeItem("nextAction");
  window.open("/", "_self");
};
function App() {
  const history = useNavigate();

  let token = localStorage.getItem("mv_token");

  let intial = {
    isAuthenticated: token ? true : false,
    
  };

  const [state, dispatch] = useReducer(loginReducer, intial);
  //console.log(state);
  const Logout = () => {
    logoutUser();
    refreshToken(null);
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("mv_token");
    sessionStorage.removeItem("nextPage");
    sessionStorage.removeItem("nextAction");
    dispatch({ type: "LOGOUT" });
    history("/");
  };
  const IsLogged = () => {
    refreshToken(localStorage.getItem("mv_token"));
    dispatch({ type: "LOGIN" });

  };
  
 
  refreshToken(localStorage.getItem("mv_token"));

  useEffect(() => {
    if(token === null){
      dispatch({ type: "LOGOUT" });
    // history("/");
      
    }
  },[token])


  // console.log("Login State",localStorage.getItem("mv_token"));
  return (
    <>
      <ToastContainer />
      <ScrollToTop />
      <Routes>
        {/* //// page that NOT VISIBLE after user login*/}
        <Route
          element={<AuthRoute state={state} logout={Logout} Login={IsLogged} />}
        >
          <Route path="/user-management" element={<ManageAccount />} />
        </Route>

        {/* //// page that VISIBLE only  after user login*/}
        <Route
          element={
            <PrivateRoute state={state} logout={Logout} Login={IsLogged} />
          }
        >
          <Route path="/events/:id" element={<InnovationEventDetails />} />
          <Route
            path="/department/academy-collaboration/program/report-problem"
            element={<ReportProblemPage />}
          />
          <Route
            path="/department/academy-collaboration/program/submit-solution"
            element={<SubmitSolutionsPage />}
          />
          <Route
            path="/department/inovation/registration/:id"
            element={<EdcRegistration />}
          />
          <Route
            path="/department/inovation/registration"
            element={<RegistrationPage />}
          />
          <Route
            path="/department/inovation/ARISERegistration"
            element={<AriseRegistration />}
          />
           
          <Route
            path="/payment-list"
            element={<PaymentListing />}
          />
          <Route path="/dashboard" element={<DataForm />} />
          <Route path="/submit-internship" element={<SubmitIntershipPage />} />
        </Route>
        {/* //// page that dont need authentication */}
        <Route
          element={
            <PublicRoute state={state} logout={Logout} Login={IsLogged} />
          }
        >
           <Route path="/payment/confirmation/:orderid/:bid" element={<PaymentSuccessPage/>}/>
          <Route path="/" element={<HomePage />} />
          <Route path="/verify-email/:id" element={<VerifyEmail />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/underconstruction" element={<UnderConstruction />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/our-team" element={<TeamPage />} />
          <Route path="/department/inovation/:dept/:id" element={<Inovation />} />
          <Route path="/advisory-committee" element={<AdvisoryCommittee />} />
          <Route path="/tenders" element={<TendersPage />} />
          <Route path="/carrers" element={<CarrersPage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogsdetails" element={<BlogDetailPage />} />
          <Route path="/department/enterprise-development" element={<EnterpreneurshipDevelopmentCenter />} />
          <Route path="/campus/:id" element={<CampusPage />} />
          <Route path="/aspirantform" element={<AspirantForm />} />
          <Route path="/edcform" element={<EDCForm />} />
          <Route path="/researchform" element={<ResearchForm />} />
          <Route path="/expertform" element={<ExpertForm />} />
          <Route path="/bgp-gform" element={<BgpGoogleForm />} />
          <Route path="/homeNews-detail/:id" element={<HomeNewsDetails/>}/>
          <Route path="/payment" element={<PaymentDetails/>}/>
          <Route path="/programs/enterprise-development/details" element={<ProgramEnterpriseDetailPage/>}/>
         

          <Route
            path="/internship-summary"
            element={<InternshipSummaryPage />}
          />
          <Route
            path="/department/inovation/events"
            element={<EventsListingPage />}
          />
          <Route path="/programs-listing" element={<ProgramsListingPage />} />
          <Route path="/schemes-listing" element={<SchemesListingPage />} />
          <Route
            path="/internship-application"
            element={<InternshipApplicationPage />}
          />
          <Route path="/our_webinars" element={<OurWebinar />} />
          <Route path="/indform" element={<IndustryForm />} />
          <Route path="/kied_videos" element={<KiedVideos />} />

          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/materials" element={<MaterialsPage />} />
          <Route
            path="/internship-details"
            element={<InternshipDetailPage />}
          />

          <Route
            path="/department/inovation/programs"
            element={<ProgramsPage />}
          />
          <Route path="/directory" element={<DirectoryPage />} />
          <Route
            path="/department/academy-collaboration/:id"
            element={<IndustryAccademyPage />}
          />
          <Route
            path="/BuildingProgramSchemes"
            element={<BuildingProgramSchemes />}
          />
          <Route
            path="/AssistancePromotion"
            element={<AssistancePromotion />}
          />
          <Route
            path="/MarketInfrastructure"
            element={<MarketInfrastructure />}
          />
          <Route
            path="/InternshipEventDetails/:id"
            element={<InternshipEventDetails />}
          />
          <Route path="/schemes" element={<SchemesPage />} />
          <Route path="/ksidc-schemes" element={<KsidcSchemesPage />} />
          <Route path="/programs" element={<ResourceProgramPage />} />
          <Route path="/events" element={<ResourcesEventsPage />} />
          <Route path="/webinar" element={<WebinarPage />} />
          <Route path="/edc-schemes" element={<EDCSchemesPage />} />
          <Route
            path="/internship-summary"
            element={<InternshipSummaryPage />}
          />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/news/:id" element={<NewsDetailPage />} />
          <Route path="/newprograms/:id" element={<NewProgramsPage />} />
          <Route path="/program/:id" element={<ProgramDetailPage />} />
         
          <Route
            path="/inevents/:id"
            element={<InnovationStatEventDetails />}
          />
          <Route path="/schemes/:id" element={<SchemeDetailPage />} />
          <Route path="/department/EDC/:id" element={<EDCPage />} />
          <Route
            path="/department/EDC/program/:id"
            element={<EdcNewProgram />}
          />
          <Route path="/edcEvents/:id" element={<EdcEventDetails />} />

          <Route
            path="/department/academy-collaboration/program"
            element={<IndAccProgramPage />}
          />

          <Route path="/department/inovation/faq" element={<FaqPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/existing-entrepreneurs" element={<ExistingEntForm />} />
          <Route path="/reset-password/:id" element={<NewPasswordPage />} />
          <Route path="/admin/reset-password/:id" element={<NewAdminPasswordPage />} />
          <Route path="*" element={<UnderConstruction />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
