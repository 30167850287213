import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { createProfile } from "../../actions/api.functions";
import { getYearlyData, logedUser } from "../../apis/auth.apis";
import KiedIcons from "../../assets/icons/kied.icons.component";
import KiedButton from "../../components/buttons/button/button.component";
import FileUploadDash from "../../components/file.upload-dash/fileupload.component";
import LoaderComponent from "../../components/loader/loader.component";
import RadioButtonDash from "../../components/radiobutton-dash/radiobutton";
import SelectBoxDash from "../../components/selectbox-dash/SelectBox.component";
import TextFieldDash from "../../components/textfield-dash/TextField.component";
import useScreenBreakPoint from "../../hooks/useScreenBreakPoint";
import {
  businessStage,
  businessduration,
  gender,
  institution,
  mode,
  radiooption,
  schoolCollege,
} from "../edc.registration/registrationform.data";
import {
  districts,
  expertise,
  qualification,
} from "../registration/registrationform.data";
import "./dashboard.styles.css";
import PrintDashBoard from "./printDashBoard";
import AspiringRegistration from "./sections/forms/aspiringRegistration";
import AspiringDataForm from "./sections/preview/aspiringform";
import EDClubDataForm from "./sections/preview/edclubform";
import ExistingDataForm from "./sections/preview/existingform";
import ExpertDataForm from "./sections/preview/expertform";
import IndustryDataForm from "./sections/preview/industryform";
import SchoolDataForm from "./sections/preview/schoolsform";
import UniversityDataForm from "./sections/preview/universityform";
import ExistingRegistration from "./sections/forms/existingRegistration";
import UniversityRegistration from "./sections/forms/universityRegistration";
import IndustryRegistration from "./sections/forms/industryRegistration";
import ExpertRegistration from "./sections/forms/expertRegistration";
import EdclubRegistration from "./sections/forms/edclubRegistration";
import SchoolForm from "./sections/forms/schoolForm";

function DataForm() {
  let width = useScreenBreakPoint();
  const aspiringFormRef = useRef(null);
  const schoolFormRef = useRef(null);
  const universityFormRef = useRef(null);
  const existingFormRef = useRef(null);
  const industryFormRef = useRef(null);
  const expertFormRef = useRef(null);
  const edclubFormRef = useRef(null);
  const [formdata, setFormdata] = useState({});
  const [error, setError] = useState({});
  const [completed, setCompleted] = useState(true);
  const [errorSize, setErrorSize] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [fielderror, setFieldError] = useState(true);
  const [profiledit, setProfiledit] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [userData, setUserData] = useState({});
  const [keyValue, setKeyValue] = useState(0);
  const [edcView, setEdcView] = useState(0);
  const [yearlyData, setYearlyData] = useState([]);
  const printContainer = useRef(null);
  const navigate = useNavigate();

  // let do_you_own_business = "yes";
  let userid = userData?.id;
  let userrole = userData?.role;
  let accountname = userData?.name;
  let accountno = userData?.mobile;
  let whatsapp_no = userData?.whatsapp_no;

  let edcUser = [
    "school_name",
    "address",
    "head_name",
    "head_designation",
    "head_email",
    "head_contact_no",
    "name_spc",
    "designation_spc",
    "email_spc",
    "contact_no_spc",
    "institution_type",
    "area_expertise",
    "auth_letter",
  ];

  let extUser = [
    "gender",
    "dob",

    "address",
    "district",
    "highest_educational_qualification",
    "product_type",
    "stage_of_business",
    "how_long_business",
    "mode_of_business",
    ...(formdata.mode_of_business === "Other" ? ["other_mode_business"] : []),
  ];
  let acaUser = [
    "organization_name",
    "address",
    "head_name",
    "head_designation",
    "head_email",
    "head_contact",
    "name_single_poc",
    "designation_single_poc",
    "email_single_poc",
    "contact_single_poc",
    "institution_type",

    "area_of_expertise",
    "authorization_letter",
    ...(formdata.institution_type === "Others" ? ["details"] : []),
  ];
  let indUser = [
    "organization_name",
    "address",
    "head_name",
    "head_designation",
    "head_email",
    "head_contact_no",
    "name_single_poc",
    "designation_single_poc",
    "email_single_poc",
    "contact_single_poc",
    "institution_type",
    "area_of_expertise",
    "authorization_letter",
  ];
  let menUser = [
    "gender",
    "highest_educational_qualification",
    "online_profile",
    "area_expertise",

    "qualification_proof",
    "experience_proof",
    ...(formdata.area_expertise === "Others" ? ["expspecify"] : []),
  ];
  let edclubUser = [
    ...(formdata.is_member === 1 ? ["supporting_document"] : []),
    "institution_type",
    "district_institution",
    "institution_name",
    "address_institution",
    "approval_letter",
    "name_faculty_coordinator",
    "whatsapp_faculty_coordinator",
    "email_faculty_coordinator",
    "name_student_coordinator",
    "whatsapp_student_coordinator",
    "count_students",
    ...(formdata.count_students !== "" && formdata.count_students !== 0
      ? ["activity_details"]
      : []),
    "gst_details",
    "innovation_idea",
    ...(formdata.innovation_idea === 1 ? ["has_innovation_idea"] : []),
  ];

  const validateField = () => {
    let errorField = {};
    if (userData?.role === 3) {
      edcUser.forEach((item) => {
        if (!formdata[item] || formdata[item] === "") {
          errorField[item] = "Please fill in the details";
          setSubmitted(false);
        }
      });
    }
    if (userData?.role === 4) {
      extUser.forEach((item) => {
        if (!formdata[item] || formdata[item] === "") {
          errorField[item] = "Please fill in the details";
          setSubmitted(false);
        }
      });
    }
    if (userData?.role === 5) {
      acaUser.forEach((item) => {
        if (!formdata[item] || formdata[item] === "") {
          errorField[item] = "Please fill in the details";
          setSubmitted(false);
        }
      });
    }
    if (userData?.role === 6) {
      indUser.forEach((item) => {
        if (!formdata[item] || formdata[item] === "") {
          errorField[item] = "Please fill in the details";
          setSubmitted(false);
        }
      });
    }
    if (userData?.role === 7) {
      menUser.forEach((item) => {
        if (!formdata[item] || formdata[item] === "") {
          errorField[item] = "Please fill in the details";
          setSubmitted(false);
        }
      });
    }
    if (userData?.role === 8) {
      edclubUser.forEach((item) => {
        if (!formdata[item] || formdata[item] === "") {
          errorField[item] = "Please fill in the details";
          setSubmitted(false);
        }
      });
    }
    // console.log(errorField);
  };

  const handleTextFieldChange = (value, name) => {
    // console.log(name, value);
    setFormdata({
      ...formdata,
      [name]: value.replace(
        /<\/?[^>]+(>|$)|[~`!<\>#$^{[}\]:;?+=_\|]|eval|windows/gi,
        ""
      ),
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printContainer.current,
    bodyClass: "",

    copyStyles: true,

    onBeforePrint: () => {},
  });

  const fetchprofile = () => {
    setIsActive(true);
    logedUser()
      .then((res) => {
        // console.log(res);
        setUserData(res.user);
        if (res.user.is_profile_complete == 1) {
          setCompleted(false);
          let next = sessionStorage.getItem("next_path");
          if (next) {
            navigate(next);
          }
        }
        localStorage.setItem("user", JSON.stringify(res.user));
        let profileData = res.user?.profile[0] || {};
        profileData["name"] = res?.user?.name;
        profileData["mobile"] = res?.user?.mobile;
        profileData["whatsapp_no"] = res?.user?.whatsapp_no;
        profileData["is_attended_kied_program"] =
          profileData["is_attended_kied_program"] || "0";
        // console.log(profileData);
        if (Object.keys(profileData).length) {
          profileData["specify_religion"] = profileData["religion"];
          if (
            profileData.religion &&
            profileData.religion !== "" &&
            profileData.religion !== "Hindu" &&
            profileData.religion !== "Muslim" &&
            profileData.religion !== "Christian"
          ) {
            profileData["religion"] = "Others";
          }

          profileData["other_mode_business"] = profileData["mode_of_business"];
          if (
            profileData.mode_of_business &&
            profileData.mode_of_business !== "" &&
            profileData.mode_of_business !== "Manufacturing / Production" &&
            profileData.mode_of_business !== "Packing / Repacking" &&
            profileData.mode_of_business !== "Trading / Marketing" &&
            profileData.mode_of_business !== "Services"
          ) {
            profileData["mode_of_business"] = "Other";
            // profileData["other_mode_business"] =
            //   profileData["mode_of_business"];
          }
          if (userData?.role === 2) {
            if (!profileData?.is_attended_kied_program) {
              profileData["is_attended_kied_program"] = "0";
            }
          }
          if (userData?.role === 7) {
            profileData["expspecify"] = profileData["area_expertise"];
            if (
              profileData.area_expertise &&
              profileData.area_expertise !== "" &&
              profileData.area_expertise !== "IPR" &&
              profileData.area_expertise !== "Legal and Company Secretary" &&
              profileData.area_expertise !== "GEM" &&
              profileData.area_expertise !== "E-Commerce"
            ) {
              profileData["area_expertise"] = "Others";
            }
          }
          if (userData?.role === 8) {
            // profileData["expspecify"] = profileData["area_expertise"];
            if (
              profileData.has_innovation_idea &&
              profileData.has_innovation_idea !== "" &&
              profileData.has_innovation_idea !== "null"
            ) {
              profileData["innovation_idea"] = "1";
            } else {
              profileData["innovation_idea"] = "0";
            }
          }
        }
        setFormdata(profileData);
        // console.log(userData?.profile[0]);
        setKeyValue(keyValue + 1);
        setIsActive(false);
      })
      .catch((err) => {
        // console.log(err);
        setSubmitted(false);
        setIsActive(false);
      });
  };

  const handleEdcPreview = (d) => {
    // console.log(d)
    setCompleted(true);
    setSubmitted(false);
    setEdcView(d);
  };
  const yearData = () => {
    getYearlyData()
      .then((res) => {
        console.log(res);
        setYearlyData(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message || "something went wrong");
      });
  };
  useEffect(() => {
    yearData();
  }, []);

  const handleEdit = () => {
    // console.log(submitted)
    // console.log("edit");
    setCompleted(true);
    setSubmitted(false);
    if (userData.role === 8) {
      setEdcView(4);
    }
  };

  const handleProfileEdit = () => {
    setSubmitted(false);
    setProfiledit(true);
  };

  const handlepopupEdit = () => {
    navigate("/");
  };

  useEffect(() => {
    fetchprofile();
  }, []);

  const handleChange = (e) => {
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value.replace(
        /<\/?[^>]+(>|$)|[~`!<\>#$^{[}\]:;?+=_\|]|eval|windows/gi,
        ""
      ),

      // ...(e.target.name === "do_you_own_business" && { company_name: null }),
      ...(e.target.name === "is_attended_kied_program" && {
        program_details_attended: null,
      }),
      ...(e.target.name === "is_member" && {
        supporting_document: null,
      }),
      ...(e.target.name === "innovation_idea" && {
        has_innovation_idea: null,
      }),
      // ...(e.target.name === "do_you_own_business" && {
      //   company_name: null,
      // }),
      /////////////////////////////////
      ...(e.target.name === "mode_of_business" &&
        (e.target.value === "Other"
          ? { other_mode_business: null }
          : {
              other_mode_business: e.target.value.replace(
                /<\/?[^>]+(>|$)|[~`!<\>#$^{[}\]:;?+=_\|]|eval|windows/gi,
                ""
              ),
            })),

      ...(e.target.name === "institution" &&
        (e.target.value === "Others"
          ? { institution_type: null }
          : {
              institution_type: e.target.value.replace(
                /<\/?[^>]+(>|$)|[~`!<\>#$^{[}\]:;?+=_\|]|eval|windows/gi,
                ""
              ),
            })),

      ...(e.target.name === "expertise" &&
        (e.target.value === "Others"
          ? { expspecify: null }
          : {
              expspecify: e.target.value.replace(
                /<\/?[^>]+(>|$)|[~`!<\>#$^{[}\]:;?+=_\|]|eval|windows/gi,
                ""
              ),
            })),

      ...(e.target.name === "religion" &&
        (e.target.value === "Others"
          ? { specify_religion: null }
          : {
              specify_religion: e.target.value.replace(
                /<\/?[^>]+(>|$)|[~`!<\>#$^{[}\]:;?+=_\|]|eval|windows/gi,
                ""
              ),
            })),
    });
    console.log(e.target.value, e.target.name);
  };
  // console.log(errorSize);

  const handleManageRegister = () => {
    switch (userData?.role) {
      case 2:
        aspiringFormRef.current?.handleSubmit();
      case 3:
        schoolFormRef.current?.handleSubmit();
      case 5:
        universityFormRef.current?.handleSubmit();
      case 4:
        existingFormRef.current?.handleSubmit();
      case 6:
        industryFormRef.current?.handleSubmit();
      case 7:
        expertFormRef.current?.handleSubmit();
      case 8:
        edclubFormRef.current?.handleSubmit();
    }
  };
  const handleBasicSubmit = (fdata) => {
    let payload = new FormData();
    if (formdata.name && formdata.name !== "") {
      payload.append("name", formdata.name);
    } else {
      payload.append("name", accountname);
    }

    if (formdata.mobile && formdata.mobile !== "") {
      payload.append("mobile", formdata.mobile);
    } else {
      payload.append("mobile", accountno);
    }
    if (formdata.whatsapp_no && formdata.whatsapp_no !== "") {
      payload.append("whatsapp_no", formdata.whatsapp_no);
    } else {
      payload.append("whatsapp_no", whatsapp_no);
    }
    payload.append("user_id", userid);
    payload.append("role", userrole);
    createProfile(payload)
      .then((res) => {
        fetchprofile();
        // setFormdata(res.data);
        // console.log(res);
        setCompleted(false);
        setProfiledit(false);
        setSubmitted(false);
        toast.success(res?.message);
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        setSubmitted(false);
        setCompleted(true);
        toast.error(err?.response?.data?.message || "something went wrong");
        setCompleted(true);
      });
  };
  const handleSubmit = (fdata) => {
    console.log(fdata, error);
    if (formdata.name && formdata.name !== "") {
      fdata.append("name", formdata.name);
    } else {
      fdata.append("name", accountname);
    }

    if (formdata.mobile && formdata.mobile !== "") {
      fdata.append("mobile", formdata.mobile);
    } else {
      fdata.append("mobile", accountno);
    }
    if (formdata.whatsapp_no && formdata.whatsapp_no !== "") {
      fdata.append("whatsapp_no", formdata.whatsapp_no);
    } else {
      fdata.append("whatsapp_no", whatsapp_no);
    }

    // payload.append("mobile", formdata.mobile);
    // payload.append("whatsapp_no", formdata.whatsapp_no);
    fdata.append("user_id", userid);
    fdata.append("role", userrole);

    createProfile(fdata)
      .then((res) => {
        fetchprofile();
        // setFormdata(res.data);
        // console.log(res);
        setCompleted(false);
        setProfiledit(false);
        setSubmitted(false);
        toast.success(res?.message);
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        setSubmitted(false);
        setCompleted(true);
        toast.error(err?.response?.data?.message || "something went wrong");
        setCompleted(true);
      });
  };

  // console.log(userid);
  const fileUpload = (e) => {
    // console.log(formdata);
    // console.log(e.target.files);
    let name = e.target.name;
    let value = e.target.files[0];
    let filename = e.target.files[0].name;
    let filesize = e.target.files[0].size / 1024;
    // accept = ".pdf, .png, .jpeg";
    let validationFormats = e.target.accept
      .split(",")
      .join("|")
      .split(".")
      .join("")
      .split(" ")
      .join("");

    var regVal = new RegExp(`^.*.(${validationFormats})$`);

    if (regVal.test(filename)) {
      if (filesize < 5120) {
        setFormdata({
          ...formdata,
          [name]: value,
          [name + "_name"]: filename,
        });
        setErrorSize({ ...errorSize, [name]: null });
      } else {
        setFormdata({
          ...formdata,
          [name]: undefined,
          [name + "_name"]: undefined,
        });
        setErrorSize({
          ...errorSize,
          [name]: "Please upload a file less than 5 mb",
        });
      }
    } else {
      setFormdata({
        ...formdata,
        [name]: undefined,
        [name + "_name"]: undefined,
      });
      setError({ [name]: true });
    }
  };

  let username = userData?.name;
  const nameArray = username?.substring(0, 2) || "";
  const handleSuccess = () => {
    setCompleted(false);
    setProfiledit(false);
  };
  const handleFailure = () => {
    setCompleted(true);
  };
  if (isActive) {
    return <LoaderComponent open />;
  }
  const handleEdcNext = (status) => {
    setCompleted(true);
    setSubmitted(false);
    setEdcView(status);
  };

  const handleregister = () => {
    console.log("1");
    setSubmitted(true);
    setProfiledit(false);
    console.log("2");
    let payload = new FormData();

    // navigate("/success");
    if (formdata?.name && formdata?.name !== "") {
      payload.append("name", formdata?.name);
    } else {
      payload.append("name", accountname);
    }

    if (formdata?.mobile && formdata?.mobile !== "") {
      payload.append("mobile", formdata?.mobile);
    } else {
      payload.append("mobile", accountno);
    }
    if (formdata?.whatsapp_no && formdata?.whatsapp_no !== "") {
      payload.append("whatsapp_no", formdata?.whatsapp_no);
    } else {
      payload.append("whatsapp_no", whatsapp_no);
    }

    // payload.append("mobile", formdata?.mobile);
    // payload.append("whatsapp_no", formdata?.whatsapp_no);
    payload.append("user_id", userid);
    payload.append("role", userrole);

    if (userData?.role === 2) {
    }
    if (userData?.role === 3) {
      if (formdata?.school_name !== "") {
        payload.append("school_name", formdata?.school_name);
      }
      if (formdata?.address !== "" && formdata?.address) {
        payload.append("address", formdata?.address);
      }
      if (formdata?.head_name !== "" && formdata?.head_name) {
        payload.append("head_name", formdata?.head_name);
      }
      if (formdata?.head_designation !== "" && formdata?.head_designation) {
        payload.append("head_designation", formdata?.head_designation);
      }
      if (formdata?.head_email !== "" && formdata?.head_email) {
        payload.append("head_email", formdata?.head_email);
      }
      if (formdata?.head_contact_no !== "" && formdata?.head_contact_no) {
        payload.append("head_contact_no", formdata?.head_contact_no);
      }
      if (formdata?.name_spc !== "" && formdata?.name_spc) {
        payload.append("name_spc", formdata?.name_spc);
      }
      if (formdata?.designation_spc !== "" && formdata?.designation_spc) {
        payload.append("designation_spc", formdata?.designation_spc);
      }
      if (formdata?.email_spc !== "" && formdata?.email_spc) {
        payload.append("email_spc", formdata?.email_spc);
      }
      if (formdata?.contact_no_spc !== "" && formdata?.contact_no_spc) {
        payload.append("contact_no_spc", formdata?.contact_no_spc);
      }
      if (formdata?.institution_type !== "" && formdata?.institution_type) {
        payload.append("institution_type", formdata?.institution_type);
      }
      if (formdata?.area_expertise !== "" && formdata?.area_expertise) {
        payload.append("area_expertise", formdata?.area_expertise);
      }
      if (formdata?.auth_letter !== "" && formdata?.auth_letter) {
        payload.append("auth_letter", formdata?.auth_letter);
      }

      payload.append("user_id", userid);
    }
    if (userData?.role === 4) {
      if (formdata?.gender !== "" && formdata?.gender) {
        payload.append("gender", formdata?.gender);
      }
      if (formdata?.dob !== "" && formdata?.dob) {
        payload.append("dob", formdata?.dob);
      }
      if (formdata?.address !== "" && formdata?.address) {
        payload.append("address", formdata?.address);
      }
      if (formdata?.district !== "" && formdata?.district) {
        payload.append("district", formdata?.district);
      }
      if (
        formdata?.highest_educational_qualification !== "" &&
        formdata?.highest_educational_qualification
      ) {
        payload.append(
          "highest_educational_qualification",
          formdata?.highest_educational_qualification
        );
      }
      // if (formdata?.do_you_own_business !== "" && formdata?.do_you_own_business) {
      //   payload.append("do_you_own_business", 1);
      // }
      if (formdata?.company_name !== "" && formdata?.company_name) {
        payload.append("company_name", formdata?.company_name);
      }
      if (formdata?.stage_of_business !== "" && formdata?.stage_of_business) {
        payload.append("stage_of_business", formdata?.stage_of_business);
      }
      if (formdata?.how_long_business !== "" && formdata?.how_long_business) {
        payload.append("how_long_business", formdata?.how_long_business);
      }
      payload.append("do_you_own_business", 1);

      if (formdata?.product_type !== "" && formdata?.product_type) {
        payload.append("product_type", formdata?.product_type);
      }

      if (formdata?.mode_of_business === "Other") {
        payload.append("mode_of_business", formdata?.other_mode_business ?? "");
        // payload.append("others", null);
      } else {
        if (formdata?.mode_of_business !== "" && formdata?.mode_of_business) {
          payload.append("mode_of_business", formdata?.mode_of_business);
          // payload.append("others", formdata?.others);
        }
      }
    }

    if (userData?.role === 6) {
      if (formdata?.organization_name !== "" && formdata?.organization_name) {
        payload.append("organization_name", formdata?.organization_name);
      }
      if (formdata?.address !== "" && formdata?.address) {
        payload.append("address", formdata?.address);
      }
      if (formdata?.head_name !== "" && formdata?.head_name) {
        payload.append("head_name", formdata?.head_name);
      }
      if (formdata?.head_designation !== "" && formdata?.head_designation) {
        payload.append("head_designation", formdata?.head_designation);
      }
      if (formdata?.head_email !== "" && formdata?.head_email) {
        payload.append("head_email", formdata?.head_email);
      }
      if (formdata?.head_contact_no !== "" && formdata?.head_contact_no) {
        payload.append("head_contact_no", formdata?.head_contact_no);
      }
      if (formdata?.name_single_poc !== "" && formdata?.name_single_poc) {
        payload.append("name_single_poc", formdata?.name_single_poc);
      }
      if (
        formdata?.designation_single_poc !== "" &&
        formdata?.designation_single_poc
      ) {
        payload.append(
          "designation_single_poc",
          formdata?.designation_single_poc
        );
      }
      if (formdata?.email_single_poc !== "" && formdata?.email_single_poc) {
        payload.append("email_single_poc", formdata?.email_single_poc);
      }
      if (formdata?.contact_single_poc !== "" && formdata?.contact_single_poc) {
        payload.append("contact_single_poc", formdata?.contact_single_poc);
      }
      if (formdata?.area_of_expertise !== "" && formdata?.area_of_expertise) {
        payload.append("area_of_expertise", formdata?.area_of_expertise);
      }
      if (formdata?.institution_type !== "" && formdata?.institution_type) {
        payload.append("institution_type", formdata?.institution_type);
      }
      if (
        formdata?.authorization_letter !== "" &&
        formdata?.authorization_letter
      ) {
        payload.append("authorization_letter", formdata?.authorization_letter);
      }
    }
    if (userData?.role === 7) {
      if (formdata?.gender !== "" && formdata?.gender) {
        payload.append("gender", formdata?.gender);
      }
      if (
        formdata?.highest_educational_qualification !== "" &&
        formdata?.highest_educational_qualification
      ) {
        payload.append(
          "highest_educational_qualification",
          formdata?.highest_educational_qualification
        );
      }
      if (formdata?.online_profile !== "" && formdata?.online_profile) {
        payload.append("online_profile", formdata?.online_profile);
      }

      if (formdata?.area_expertise === "Others") {
        payload.append("area_expertise", formdata?.expspecify ?? "");
      } else {
        if (formdata?.area_expertise !== "" && formdata?.area_expertise) {
          payload.append("area_expertise", formdata?.area_expertise);
        }
      }
      if (
        formdata?.qualification_proof !== "" &&
        formdata?.qualification_proof
      ) {
        payload.append("qualification_proof", formdata?.qualification_proof);
      }
      if (formdata?.experience_proof !== "" && formdata?.experience_proof) {
        payload.append("experience_proof", formdata?.experience_proof);
      }
      if (formdata?.other_empanelment !== "" && formdata?.other_empanelment) {
        payload.append("other_empanelment", formdata?.other_empanelment);
      }
    }
    if (userData?.role === 8) {
      if (formdata?.is_member !== "" && formdata?.is_member) {
        payload.append("is_member", formdata?.is_member);
      }

      if (formdata?.is_member == 1) {
        payload.append("supporting_document", formdata?.supporting_document);
      } else {
        payload.append("is_member", 0);
        payload.append("supporting_document", null);
      }
      if (formdata?.institution_type !== "" && formdata?.institution_type) {
        payload.append("institution_type", formdata?.institution_type);
      }
      if (
        formdata?.district_institution !== "" &&
        formdata?.district_institution
      ) {
        payload.append("district_institution", formdata?.district_institution);
      }
      if (formdata?.institution_name !== "" && formdata?.institution_name) {
        payload.append("institution_name", formdata?.institution_name);
      }
      if (
        formdata?.address_institution !== "" &&
        formdata?.address_institution
      ) {
        payload.append("address_institution", formdata?.address_institution);
      }
      if (formdata?.approval_letter !== "" && formdata?.approval_letter) {
        payload.append("approval_letter", formdata?.approval_letter);
      }
      if (
        formdata?.name_faculty_coordinator !== "" &&
        formdata?.name_faculty_coordinator
      ) {
        payload.append(
          "name_faculty_coordinator",
          formdata?.name_faculty_coordinator
        );
      }
      if (
        formdata?.whatsapp_faculty_coordinator !== "" &&
        formdata?.whatsapp_faculty_coordinator
      ) {
        payload.append(
          "whatsapp_faculty_coordinator",
          formdata?.whatsapp_faculty_coordinator
        );
      }
      if (
        formdata?.email_faculty_coordinator !== "" &&
        formdata?.email_faculty_coordinator
      ) {
        payload.append(
          "email_faculty_coordinator",
          formdata?.email_faculty_coordinator
        );
      }
      if (
        formdata?.name_student_coordinator !== "" &&
        formdata?.name_student_coordinator
      ) {
        payload.append(
          "name_student_coordinator",
          formdata?.name_student_coordinator
        );
      }
      if (
        formdata?.whatsapp_student_coordinator !== "" &&
        formdata?.whatsapp_student_coordinator
      ) {
        payload.append(
          "whatsapp_student_coordinator",
          formdata?.whatsapp_student_coordinator
        );
      }
      if (
        formdata?.email_student_coordinator !== "" &&
        formdata?.email_student_coordinator
      ) {
        payload.append(
          "email_student_coordinator",
          formdata?.email_student_coordinator
        );
      }
      if (formdata?.count_students !== "" && formdata?.count_students) {
        payload.append("count_students", formdata?.count_students);
      }
      if (
        formdata?.count_students !== "" &&
        formdata?.count_students &&
        formdata?.count_students !== 0
      ) {
        if (formdata?.activity_details !== "" && formdata?.activity_details) {
          payload.append("activity_details", formdata?.activity_details);
        }
      }
      if (formdata?.gst_details !== "" && formdata?.gst_details) {
        payload.append("gst_details", formdata?.gst_details);
      }
      if (formdata?.innovation_idea && formdata?.innovation_idea == 1) {
        if (
          formdata?.has_innovation_idea !== "" &&
          formdata?.has_innovation_idea
        ) {
          payload.append("has_innovation_idea", formdata?.has_innovation_idea);
        }
      } else {
        payload.append("has_innovation_idea", null);
      }
    }
    console.log("3");
    let flag = true;
    if (flag) {
      console.log("hey Hey Hey");
      const errorField = validateField();
      // console.log("hey Hey Hey",errorField)
      if (Object.keys(errorField).length === 0) {
        createProfile(payload)
          .then((res) => {
            fetchprofile();
            // setFormdata(res.data);
            setCompleted(false);
            setProfiledit(false);
            setSubmitted(false);
            toast.success(res?.message);
            console.log("4");
          })
          .catch((err) => {
            setSubmitted(false);
            setCompleted(true);
            toast.error(err.response.data.message);
            setCompleted(true);
            console.log("5");
          });
      } else {
        setFieldError(errorField);
        setSubmitted(false);
        setCompleted(true);
        console.log("6");
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please fill all the required fields (Mandatory fields are marked in red)",
          confirmButtonColor: "#ffa93c",
          confirmButtonText: "Ok",
        });
      }
    }
  };

  return (
    <>
      {/* <SmallBanner /> */}
      <div className="header-border-form"> </div>
      <div
        style={{ backgroundColor: "#dc8a7f", height: "70px" }}
        className="d-flex justify-content-center"
      >
        {width === "sm" || width === "xs" ? (
          ""
        ) : (
          <div className="h-100 px-5 ml-4 subBannerProfile d-flex align-items-center">
            {" "}
            Profile
          </div>
        )}
        {/* <div className="h-100 subBannerComplete ml-4 d-flex align-items-center">
          {" "}
          PROFILE COMPLETENESS: 0%
        </div> */}
      </div>
      <div style={{ backgroundColor: "#dc8a7f" }} className="mb-5"></div>
      <div
        className={`auto-container d-flex  ${
          width === "xs" || width === "sm"
            ? "flex-column align-items-center"
            : userData?.role === 9 || userData?.role === 10
            ? "justify-content-center"
            : "justify-content-between"
        } `}
      >
        <div
          className={`d-flex flex-column align-items-center text-center col-4 ${
            width === "xs" || width === "sm" ? "col-12 mb-3" : ""
          } ${
            userData?.role === 9 || userData?.role === 10
              ? "justify-content-center"
              : ""
          }`}
        >
          <div
            className={`h-100 w-100 ${
              width === "sm" || width === "xs" ? "px-2" : ""
            }`}
          >
            {width === "xs" || width === "sm" ? (
              <span className="dashlettermob  h-100">{nameArray}</span>
            ) : (
              <span className="dashletter  h-100">{nameArray}</span>
            )}
            <div className="mt-5">
              <div className=" dashboardUser">
                <b className=" mt-5">{username}</b>
              </div>
              <div className=" dashboardRole">
                {userData?.role === 2
                  ? "Aspiring Enterpreneur"
                  : userData?.role === 3
                  ? "Schools and Colleges"
                  : userData?.role === 4
                  ? " Existing Entrepreneur"
                  : userData?.role === 5
                  ? " Research Institutions and Universities"
                  : userData?.role === 6
                  ? " Industry Associations / Enablers"
                  : userData?.role === 7
                  ? " Expert / Mentors"
                  : userData?.role === 8
                  ? " ED Club"
                  : userData?.role === 9
                  ? "Student"
                  : userData?.role === 10
                  ? "Guest"
                  : ""}
              </div>
              {profiledit === false ? (
                <>
                  <div
                    className={`col-12 p-2 dashDataBox ${
                      width === "sm" || width === "xs" ? "w-100" : ""
                    } `}
                  >
                    <div className="dashdoc mt-2">User Details</div>
                    <hr />
                    <div className="d-flex justify-content-start">
                      {" "}
                      <span className="col-6 d-flex justify-content-between p-0">
                        Name<span className="p-0">:</span>{" "}
                      </span>
                      <span className="col-6  d-flex justify-content-start">
                        {accountname}
                      </span>
                    </div>
                    <div className="d-flex">
                      {" "}
                      <span className="col-6 d-flex justify-content-between p-0">
                        Phone.No<span className="p-0">:</span>{" "}
                      </span>
                      <span className="col-6  d-flex justify-content-start">
                        {accountno}
                      </span>
                    </div>
                    <div className="d-flex">
                      {" "}
                      <span className="col-6 d-flex justify-content-between p-0">
                        WhatsApp.No<span className="p-0">:</span>{" "}
                      </span>
                      <span className="col-6  d-flex justify-content-start">
                        {whatsapp_no}
                      </span>
                    </div>
                    {!completed && <br />}
                    {(completed ||
                      userData?.role === 9 ||
                      userData?.role === 10) && (
                      <div className="d-flex justify-content-center pt-2 pb-2">
                        <KiedButton
                          onClick={() => handleProfileEdit()}
                          primary
                          rounded
                          content={
                            <div className=" d-flex justify-content-center">
                              Edit
                            </div>
                          }
                        />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 p-1 dashDataBox">
                    <div className="dashdoc mt-2">User Details</div>
                    <hr />
                    <TextFieldDash
                      label="Name"
                      required
                      type="text"
                      name="name"
                      value={formdata.name ?? accountname}
                      onChange={handleTextFieldChange}
                    />
                    <br />
                    <TextFieldDash
                      label="Phone No."
                      required
                      type="phone"
                      maxLength={10}
                      minLength={10}
                      name="mobile"
                      value={formdata.mobile ?? accountno}
                      onChange={handleTextFieldChange}
                    />
                    <br />
                    <TextFieldDash
                      label="WhatsApp No."
                      name="whatsapp_no"
                      type="phone"
                      maxLength={10}
                      minLength={10}
                      value={formdata.whatsapp_no ?? whatsapp_no}
                      onChange={handleTextFieldChange}
                    />
                    <br />
                    <div className="d-flex justify-content-center pt-2 pb-2">
                      <KiedButton
                        onClick={() => handleBasicSubmit(formdata)}
                        primary
                        rounded
                        content={
                          <div className=" d-flex justify-content-center">
                            Submit
                          </div>
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              {!completed && (
                <>
                  <div
                    className={`${
                      userData?.role === 9 || userData?.role === 10
                        ? "d-none"
                        : ""
                    }`}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center py-5"
                      onClick={() => handlePrint()}
                    >
                      <a
                        href="#"
                        targt="_blank"
                        className="d-flex align-items-center"
                      >
                        <span className="ml-1 ">Print this page</span>
                        <span className="d-flex ">
                          <KiedIcons icon="Download-btn" className="ml-1" />
                        </span>
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <>
          {completed === true ? (
            <div
              className={`auto-container dashform mt-4 ml-1 mr-1 col-8 ${
                userData?.role === 9 || userData?.role === 10 ? "d-none" : ""
              }`}
            >
              {userData?.role === 2 && (
                <>
                  <AspiringRegistration
                    ref={aspiringFormRef}
                    handleChange={handleChange}
                    handleTextFieldChange={handleTextFieldChange}
                    formData={formdata}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    userData={userData}
                    error={error}
                    setError={setError}
                    handleSubmit={handleSubmit}
                    setCompleted={setCompleted}
                    fielderror={fielderror}
                    setFieldError={setFieldError}
                  />
                </>
              )}
              {userData?.role === 3 && (
                <SchoolForm
                  ref={schoolFormRef}
                  handleChange={handleChange}
                  handleTextFieldChange={handleTextFieldChange}
                  formdata={formdata}
                  submitted={submitted}
                  setSubmitted={setSubmitted}
                  userData={userData}
                  error={error}
                  setError={setError}
                  handleSubmit={handleSubmit}
                  setCompleted={setCompleted}
                  fielderror={fielderror}
                  setFieldError={setFieldError}
                  errorSize={errorSize}
                  fileUpload={fileUpload}
                  userid={userid}
                  accountname={accountname}
                  accountno={accountno}
                  whatsapp_no={whatsapp_no}
                  userrole={userrole}
                  setProfiledit={setProfiledit}
                  fetchprofile={fetchprofile}
                  createProfile={createProfile}
                  validateField={validateField}
                  FileUploadDash={FileUploadDash}
                  handleregister={handleregister}
                  schoolCollege={schoolCollege}
                />
              )}
              {userData?.role === 4 && (
                <>
                  <ExistingRegistration
                    ref={existingFormRef}
                    handleChange={handleChange}
                    handleTextFieldChange={handleTextFieldChange}
                    formdata={formdata}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    userData={userData}
                    error={error}
                    setError={setError}
                    handleSubmit={handleSubmit}
                    setCompleted={setCompleted}
                    fielderror={fielderror}
                    setFieldError={setFieldError}
                    errorSize={errorSize}
                    fileUpload={fileUpload}
                    userid={userid}
                    accountname={accountname}
                    accountno={accountno}
                    whatsapp_no={whatsapp_no}
                    userrole={userrole}
                    setProfiledit={setProfiledit}
                    fetchprofile={fetchprofile}
                    createProfile={createProfile}
                    validateField={validateField}
                  />
                </>
              )}
              {userData?.role === 5 && (
                <>
                  <UniversityRegistration
                    ref={universityFormRef}
                    handleChange={handleChange}
                    handleTextFieldChange={handleTextFieldChange}
                    formdata={formdata}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    userData={userData}
                    error={error}
                    setError={setError}
                    handleSubmit={handleSubmit}
                    setCompleted={setCompleted}
                    fielderror={fielderror}
                    setFieldError={setFieldError}
                    errorSize={errorSize}
                    fileUpload={fileUpload}
                  />
                </>
              )}
              {userData?.role === 6 && (
                <>
                  <IndustryRegistration
                    ref={industryFormRef}
                    handleChange={handleChange}
                    handleTextFieldChange={handleTextFieldChange}
                    formdata={formdata}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    userData={userData}
                    error={error}
                    setError={setError}
                    handleSubmit={handleSubmit}
                    setCompleted={setCompleted}
                    fielderror={fielderror}
                    setFieldError={setFieldError}
                    errorSize={errorSize}
                    fileUpload={fileUpload}
                    userid={userid}
                    accountname={accountname}
                    accountno={accountno}
                    whatsapp_no={whatsapp_no}
                    userrole={userrole}
                    setProfiledit={setProfiledit}
                    fetchprofile={fetchprofile}
                    createProfile={createProfile}
                    validateField={validateField}
                    FileUploadDash={FileUploadDash}
                    handleregister={handleregister}
                  />
                </>
              )}
              {userData?.role === 7 && (
                <>
                  <ExpertRegistration
                    ref={expertFormRef}
                    handleChange={handleChange}
                    handleTextFieldChange={handleTextFieldChange}
                    formdata={formdata}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    userData={userData}
                    error={error}
                    setError={setError}
                    handleSubmit={handleSubmit}
                    setCompleted={setCompleted}
                    fielderror={fielderror}
                    setFieldError={setFieldError}
                    errorSize={errorSize}
                    fileUpload={fileUpload}
                    userid={userid}
                    accountname={accountname}
                    accountno={accountno}
                    whatsapp_no={whatsapp_no}
                    userrole={userrole}
                    setProfiledit={setProfiledit}
                    fetchprofile={fetchprofile}
                    createProfile={createProfile}
                    validateField={validateField}
                    FileUploadDash={FileUploadDash}
                    handleregister={handleregister}
                    expertise={expertise}
                  />
                </>
              )}
              {userData?.role === 8 && (
                <>
                  <EdclubRegistration
                    ref={edclubFormRef}
                    handleChange={handleChange}
                    handleTextFieldChange={handleTextFieldChange}
                    formdata={formdata}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                    userData={userData}
                    error={error}
                    setError={setError}
                    handleSubmit={handleSubmit}
                    setCompleted={setCompleted}
                    fielderror={fielderror}
                    setFieldError={setFieldError}
                    errorSize={errorSize}
                    fileUpload={fileUpload}
                    userid={userid}
                    accountname={accountname}
                    accountno={accountno}
                    whatsapp_no={whatsapp_no}
                    userrole={userrole}
                    setProfiledit={setProfiledit}
                    fetchprofile={fetchprofile}
                    createProfile={createProfile}
                    validateField={validateField}
                    FileUploadDash={FileUploadDash}
                    handleregister={handleregister}
                    expertise={expertise}
                    edcView={edcView}
                    handleEdcNext={handleEdcNext}
                    Accordion={Accordion}
                  />
                </>
              )}

              <div className="d-flex justify-content-center pt-5 pb-5">
                {userData?.role !== 8 && (
                  <KiedButton
                    disabled={submitted === true}
                    onClick={() => handleManageRegister()}
                    primary
                    rounded
                    content={
                      <div className="registerbtn d-flex justify-content-center">
                        Save and Continue
                      </div>
                    }
                  />
                )}
              </div>
            </div>
          ) : (
            <div
              className={`auto-container dashform mt-4 ml-1 mr-1 col-8 ${
                userData?.role === 9 || userData?.role === 10 ? "d-none" : ""
              }`}
            >
              {userData?.role === 2 && (
                <AspiringDataForm
                  formdata={formdata}
                  handleEdit={handleEdit}
                  handlepopupEdit={handlepopupEdit}
                />
              )}
              {userData?.role === 3 && (
                <SchoolDataForm
                  formdata={formdata}
                  handleEdit={handleEdit}
                  handlepopupEdit={handlepopupEdit}
                />
              )}
              {userData?.role === 4 && (
                <UniversityDataForm
                  formdata={formdata}
                  handleEdit={handleEdit}
                  handlepopupEdit={handlepopupEdit}
                />
              )}
              {userData?.role === 5 && (
                <ExistingDataForm
                  formdata={formdata}
                  handleEdit={handleEdit}
                  handlepopupEdit={handlepopupEdit}
                />
              )}
              {userData?.role === 6 && (
                <IndustryDataForm
                  formdata={formdata}
                  handleEdit={handleEdit}
                  handlepopupEdit={handlepopupEdit}
                />
              )}
              {userData?.role === 7 && (
                <ExpertDataForm
                  formdata={formdata}
                  handleEdit={handleEdit}
                  handlepopupEdit={handlepopupEdit}
                />
              )}
              {/* {userData?.role === 8 && (
                <EDClubDataForm
                  formdata={formdata}
                  handleEdit={handleEdit}
                  handlepopupEdit={handlepopupEdit}
                />
              )} */}

              {
                userData?.role === 8 && (
                  // formdata.is_profile_complete === 1 &&
                  // formdata.status === 1 && (
                  <>
                    <EDClubDataForm
                      formdata={formdata}
                      handleEdit={handleEdit}
                      handlepopupEdit={handlepopupEdit}
                      handleEdcPreview={handleEdcPreview}
                    />
                  </>
                )
                // )
              }
            </div>
          )}
        </>
      </div>
      {completed === false && (
        <div className="d-none">
          <PrintDashBoard
            formdata={formdata}
            accountname={accountname}
            accountno={accountno}
            whatsapp_no={whatsapp_no}
            userData={userData}
            ref={printContainer}
            yearlyData={yearlyData ?? ""}
          />
        </div>
      )}
    </>
  );
}

export default DataForm;
