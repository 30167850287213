import React from "react";

function FileUpload(props) {
	return (
		<>
			{props.label && (
				<label className="formstyle">
					<span>
						{props.label} {props.required && <span style={{ color: "red" }}>*</span>}
					</span>
				</label>
			)}
			<label className="formstyle">
				<input
					{...props}
					className="inputbox "
					type={"file"}
					id="upload_plan"
					name={props.name}
					value={props.value ?? undefined}
					// onChange={props.handleFileChange}
					// fileName={props.filename ?? ""}
				/>
			</label>
		</>
	);
}

export default FileUpload;
