import React from "react";
import KiedButton from "../../../components/buttons/button/button.component";
import TextField from "../../../components/textfield/TextField.component";
import SelectBox from "../../../components/selectbox/SelectBox.component";
import FileUpload from "../../../components/file.upload/fileupload.component";
import { radiooption } from "../registrationform.data";
import { institutions } from "../registrationform.data";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  nature,
  mode,
  payment,
} from "../../submit_internship/internship-details";
import { districts } from "../../registration/registrationform.data";
import RadioButton from "../../../components/radiobutton/radiobutton";
import RichTextEditor from "../../../components/richtext_editor/richtexteditor";
import { logedUser } from "../../../apis/auth.apis";
import { useEffect } from "react";
import Swal from "sweetalert2";
import {
  registeredclub,
  getEDC,
  getquestions,
  createBooking,
} from "../../../actions/api.functions";
import { useRef } from "react";
import { updateclub } from "../../../actions/api.functions";
import {
  getPayment,
  getProgramById,
  getProgramBydeptId,
} from "../../../apis/publicApis";
import moment from "moment";

function EDCRegistration() {
  const [formdata, setFormdata] = useState({});
  const [error, setError] = useState({});
  const [regStatus, setRegStatus] = useState(false);
  const [fielderror, setFieldError] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [questions, setQuestions] = useState([]);
  const [amount, setAmount] = useState(null);
  const supportingref = useRef(null);
  const countref = useRef(null);
  const inovationgref = useRef(null);
  const gstref = useRef(null);
  const [programs, setPrograms] = useState({});
  const [resFees, setResFees] = useState(null);
  const [nonresFees, setNonresFees] = useState(null);
  const [stFees, setStFees] = useState(null);
  const [scFees, setScFees] = useState(null);

  const { id } = useParams();

  let navigate = useNavigate();
  let loggedUser = localStorage.getItem("user");

  console.log(loggedUser);
  let profileData = loggedUser ? JSON.parse(loggedUser) : null;

  const validateField = () => {
    let errorField = {};

    return errorField;
  };

  let userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  let userrole = userData?.role;

  const handleTextFieldChange = (value, name) => {
    let question = String(name);
    console.log(name, value);
    setFormdata({
      ...formdata,
      [question]: value,
    });
  };

  const handleChange = (e) => {
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value, e.target.name);
  };

  const handleFileChange = (e) => {
    // console.log("hwgehwefhgwefh");
    console.log(e.target.files[0]);
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleregister = (amount) => {
    const formData = new FormData();
    if (profileData.id) {
      formData.append("user_id", profileData.id);
    }
    if (id) {
      formData.append("program_id", id);
    }
    if (amount) {
      formData.append("fees", amount);
    }
    for (const key in formdata) {
      formData.append(`responses[${key}]`, formdata[key]);
      // Increment the index after each entry
    }
    const payload = {
      user_id: profileData.id,
      program_id: id,
      fees: amount,
      responses: formdata,
    };
    console.log(payload);
    createBooking(formData)
      .then((res) => {
        // navigate("/success");
        const bookingId = res.booking_id;
        console.log(bookingId);
        PaymentDetails(payload.fees, bookingId);
      })
      .catch((err) => {
        Swal.fire({
          icon: "warning",
          title: "You are not authorized.",
          text: err.response.data.message,
          confirmButtonColor: "#ffa93c",
          confirmButtonText: "Ok",
        });
      });
  };
  const PaymentDetails = (amount, bookingId) => {
    getPayment({ amount: amount, booking_id: bookingId })
      .then((res) => {
        console.log(res);

        sessionStorage.setItem("order_id", res.orderId);
        // setTimeout(()=>{
        //   console.log(data)
        // },100)
        let payload = {
          encRequest: res.encRequest,
          access_code: res.access_code,
          tid: res.tid,
          order_id: res.orderId,
        };

        // axios.post("https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction", payload)
        const form = document.createElement("form");
        form.method = "POST";
        form.action =
          "https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction";

        const encRequestInput = document.createElement("input");
        encRequestInput.type = "hidden";
        encRequestInput.name = "encRequest";
        encRequestInput.value = payload.encRequest;
        form.appendChild(encRequestInput);

        const accessCodeInput = document.createElement("input");
        accessCodeInput.type = "hidden";
        accessCodeInput.name = "access_code";
        accessCodeInput.value = payload.access_code;
        form.appendChild(accessCodeInput);

        document.body.appendChild(form);
        form.submit();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchQuestions = () => {
    getquestions(id)
      .then((res) => {
        console.log(res);
        setQuestions(res.data.questions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProgData = () => {
    getProgramById(id)
      .then((res) => {
        console.log(res);
        setPrograms(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculateDiscount = (d) => {
    console.log();
    const today = moment();
    const ebd = moment(d.early_bird_date);
    const isBefore = today.isSameOrBefore(ebd);
    console.log(isBefore);
    if (isBefore && d.early_bird_date) {
      setResFees(
        d.res_fees ? (d.res_fees / 100) * (100 - Number(d.discount)) : null
      );
      setNonresFees(
        d.non_res_fees
          ? (d.non_res_fees / 100) * (100 - Number(d.discount))
          : null
      );
      setStFees(
        d.st_fees ? (d.st_fees / 100) * (100 - Number(d.discount)) : null
      );
      setScFees(
        d.sc_fees ? (d.sc_fees / 100) * (100 - Number(d.discount)) : null
      );
    } else {
      setResFees(d.res_fees ?? null);
      setNonresFees(d.non_res_fees ?? null);
      setStFees(d.st_fees ?? null);
      setScFees(d.sc_fees ?? null);
    }
  };

  useEffect(() => {
    fetchQuestions();
    getProgData();
  }, []);

  useEffect(() => {
    calculateDiscount(programs);
  }, [programs]);

  const handlePayment = (amount) => {
    setAmount();
    setAmount(amount);
    handleregister(amount);
  };

  const genInput = (item) => {
    switch (item.answer_type) {
      case "text":
        return (
          <TextField
            label={item.question_text}
            required={item.mandatory === 1 ? true : false}
            name={item.id}
            value={formdata[item.id] ?? ""}
            onChange={handleTextFieldChange}
          />
        );
      case "radio":
        return (
          <RadioButton
            label={item.question_text}
            required={item.mandatory === 1 ? true : false}
            name={item.id}
            value={formdata[item.id] ?? ""}
            options={item.options}
            onChange={handleTextFieldChange}
          />
        );
      case "select":
        return (
          <SelectBox
            label={item.question_text}
            required={item.mandatory === 1 ? true : false}
            name={item.id}
            value={formdata[item.id] ?? ""}
            options={item.options}
            onChange={handleTextFieldChange}
          />
        );
      case "file":
        return (
          <FileUpload
            label={item.question_text}
            name={item.id}
            required={item.mandatory === 1 ? true : false}
            onChange={(e) => handleFileChange(e)}
          />
        );
      default:
        return null;
    }
  };
  
  console.log(resFees, nonresFees, scFees, stFees, programs);
  return (
    <div className="auto-container pb-5">
      <div className="d-flex align-items-center justify-content-between head-container">
        <div className="faq_banner registration-banner d-flex">
          <a href="/">Home&nbsp;/</a>
          {/* <a href="/department/EDC/home"> EDC&nbsp;/</a> */}
          <a className="current" href="#">
            Registration
          </a>
        </div>
        <div className=" mandatory-field d-flex justify-content-end mt-3">
          <p className="">
            <span>
              <span style={{ color: "red" }}>* </span> Mandatory Fields
            </span>
          </p>
        </div>
      </div>

      <div className="d-flex justify-content-center auto-container">
        <div className="w-50">
          {questions.map((item, i) => (
            <React.Fragment key={i}>
              {genInput(item)}
              <br />
            </React.Fragment>
          ))}
        </div>
      </div>

      <br />
      <div>
        <div className="p-3">
          <br />
          <div className="d-flex justify-content-around">
            {resFees && (
              <div className="payment-mainContainer d-flex flex-column justify-content-center align-items-center">
                <h1 className="payment_title_color">Resident Fees</h1>
                <p className="mb-0 text-center">
                  Accomodation will be provided by EDC.
                </p>
                <h1 className="payment_title_color">₹ {resFees}</h1>
                <KiedButton
                  onClick={
                    () => handlePayment(resFees)
                    // (e) =>
                    // router("/payment")
                  }
                  secondary
                  small
                  className="mt-2"
                  type="submit"
                  content={
                    <div className=" d-flex justify-content-center">
                      Submit & Pay
                    </div>
                  }
                />
              </div>
            )}
            {nonresFees && (
              <div className="payment-mainContainer d-flex flex-column justify-content-center align-items-center">
                <h1 className="payment_title_color">Normal Fees</h1>
                <p className="mb-0 text-center">
                  Accomodation will not be provided by EDC.
                </p>
                <h1 className="payment_title_color">₹ {nonresFees}</h1>
                <KiedButton
                  onClick={
                    () => handlePayment(nonresFees)
                    // (e) =>
                    // router("/payment")
                  }
                  secondary
                  small
                  className="mt-2"
                  type="submit"
                  content={
                    <div className=" d-flex justify-content-center">
                      Submit & Pay
                    </div>
                  }
                />
              </div>
            )}
            {scFees && (
              <div className="payment-mainContainer d-flex flex-column justify-content-center align-items-center">
                <h1 className="payment_title_color">SC Fees</h1>
                <p className="mb-0 text-center">
                  Accomodation will not be provided by EDC.
                </p>
                <h1 className="payment_title_color">₹ {scFees}</h1>
                <KiedButton
                  onClick={
                    () => handlePayment(scFees)
                    // (e) =>
                    // router("/payment")
                  }
                  secondary
                  small
                  className="mt-2"
                  type="submit"
                  content={
                    <div className=" d-flex justify-content-center">
                      Submit & Pay
                    </div>
                  }
                />
              </div>
            )}
            {stFees && (
              <div className="payment-mainContainer d-flex flex-column justify-content-center align-items-center">
                <h1 className="payment_title_color">ST Fees</h1>
                <p className="mb-0 text-center">
                  Accomodation will not be provided by EDC.
                </p>
                <h1 className="payment_title_color">₹ {stFees}</h1>
                <KiedButton
                  onClick={
                    () => handlePayment(stFees)
                    // (e) =>
                    // router("/payment")
                  }
                  secondary
                  small
                  className="mt-2"
                  type="submit"
                  content={
                    <div className=" d-flex justify-content-center">
                      Submit & Pay
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EDCRegistration;
