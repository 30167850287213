import React from "react";
import SmallBanner from "../../../../../components/small.banner/small.banner";
import "./InnovationDetails.css";
import InnovationEventDescription from "./sections/InnovationEventDescription";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { inovationEventData } from "../../inovationData";
import { getEventById } from "../../../../../apis/publicApis";

function InnovationEventDetails(props) {
  console.log(props)
  const param = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    
    getEventById(param.id).then((res)=>{
      console.error(res)
      let item=res
      let evItem = {
        startTime: item?.date,
        listingImage: item?.img1,
        title: item?.name,
        discount : item?.discount,
        dis_fees : item?.discounted_fees,
        ebd_date : item?.ebd_date,
        id:item?.id,
        status : item?.status,
        link:"/events/",
        location: item?.mode,
        mainDescription: item?.description,
        images:[
          {image:item?.img1},
          {image:item?.img2},
          {image:item?.img3},
          {image:item?.img4}
        ],
        fees:item?.fees
      };
      setData(evItem)
    }).catch((err) => {
      console.log(err)
    })
  }, [param.id]);
  // console.log(data);
  return (
    <div>
      <SmallBanner />
      <InnovationEventDescription data={data} />
    </div>
  );
}

export default  InnovationEventDetails;